import * as React from 'react';
import { Text, TextField, getTheme, DefaultButton, Checkbox } from '@fluentui/react';
import { APIFetchingButton } from './FetchingButton';
import { NotificationContext } from './contexts/NotificationContext';
import { useHistory } from 'react-router-dom';

export function ProductCreationForm(props) {
  const [name, setName] = React.useState('');
  const [sku, setSku] = React.useState('');
  const [series, setSeries] = React.useState('');
  const [dependency, setDependency] = React.useState('');
  const [installDir, setInstallDir] = React.useState('');
  const [imageUrl, setImageUrl] = React.useState('');
  const [moodshotUrl, setMoodshotUrl] = React.useState('');
  const [logoUrl, setLogoUrl] = React.useState('');
  const [tagLine, setTagLine] = React.useState('');
  const [manualUrl, setManualUrl] = React.useState('');
  const [detailsUrl, setDetailsUrl] = React.useState('');
  const [hidden, setHidden] = React.useState(true);
  const [channel, setChannel] = React.useState('beta');
  const [free, setFree] = React.useState(false);
  const [trialDays, setTrialDays] = React.useState(30);
  const [featured, setFeatured] = React.useState(false);
  const [featuredSortOrder, setFeaturedSortOrder] = React.useState(0);
  const [sortOrder, setSortOrder] = React.useState(0);
  const [appVersionMin, setAppVersionMin] = React.useState('');
  const [appVersionMax, setAppVersionMax] = React.useState('');
  const [lastFreeVersion, setLastFreeVersion] = React.useState('');  
  const [showOnlyIfOwned, setShowOnlyIfOwned] = React.useState(false);
  const history = useHistory();
  const theme = getTheme();
  const notify = React.useContext(NotificationContext);
  const styles = {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '1rem',
  };
  const headingStyle = {
    marginLeft: '1.25rem',
    color: theme.semanticColors.bodyText,
    flex: '0 1 100%',
  };
  const bodyStyles = {
    backgroundColor: theme.semanticColors.bodyBackground,
    border: `1px solid ${theme.semanticColors.menuDivider}`,
    margin: '.5rem 1.25rem .5rem .5rem',
    padding: '1rem 2rem',
  };
  const textFieldStyles = {
    root: {
      width: '15rem',
    },
  };
  const buttonWrapStyles = {
    paddingTop: '1rem',
    width: '15rem',
    display: 'flex',
    justifyContent: 'space-between',
  };
  const checkboxStyles = {
    root: {
      flex: '1 0 100%',
      marginTop: '1rem',
    },
  };

  const createButtonDisabled = !(name.length && sku.length && series.length);
  const handleError = ({ error, status, statusText }) => {
    notify.error({ message: `Failed to create product. ${error ?? 'Unknown Error'}. ${status ? `${status} (${statusText}).` : ''}` });
  };

  return (
    <div style={styles}>
      <Text style={headingStyle} variant={'large'}>
        New product
      </Text>
      <div style={bodyStyles}>
        <TextField value={name} onChange={e => setName(e.target.value)} label="Name" styles={textFieldStyles} required placeholder={'TITLENAME'} />
        <TextField value={sku} onChange={e => setSku(e.target.value)} label="SKU" styles={textFieldStyles} required placeholder={'VI-TITLESKU'} />
        <TextField value={series} onChange={e => setSeries(e.target.value)} label="Series" styles={textFieldStyles} required placeholder={'SERIES'} />
        <TextField value={dependency} onChange={e => setDependency(e.target.value)} label="Dependency" styles={textFieldStyles} placeholder={''} />
        <TextField value={installDir} onChange={e => setInstallDir(e.target.value)} label="Install Directory" styles={textFieldStyles} placeholder={''} />
        <TextField value={tagLine} onChange={e => setTagLine(e.target.value)} label="Tagline" styles={textFieldStyles} placeholder={'...'} />
        <TextField value={imageUrl} onChange={e => setImageUrl(e.target.value)} label="Image URL" styles={textFieldStyles} placeholder={'...'} />
        <TextField value={moodshotUrl} onChange={e => setMoodshotUrl(e.target.value)} label="Moodshot URL" styles={textFieldStyles} placeholder={'...'} />
        <TextField value={logoUrl} onChange={e => setLogoUrl(e.target.value)} label="Logo URL" styles={textFieldStyles} placeholder={'...'} />
        <TextField value={manualUrl} onChange={e => setManualUrl(e.target.value)} label="Manual URL" styles={textFieldStyles} placeholder={'...'} />
        <TextField value={detailsUrl} onChange={e => setDetailsUrl(e.target.value)} label="Details URL" styles={textFieldStyles} placeholder={'...'} />
        <TextField value={sortOrder} onChange={e => setSortOrder(e.target.value)} label="Sort order" styles={textFieldStyles} placeholder={'0'} />
        <TextField
          value={featuredSortOrder}
          onChange={e => setFeaturedSortOrder(e.target.value)}
          label="Featured sort order"
          styles={textFieldStyles}
          placeholder={'0'}
        />
        <TextField value={trialDays} onChange={e => setTrialDays(e.target.checked)} label="Trial Days" styles={checkboxStyles} placeholder={'30'} />
        <TextField value={appVersionMin} onChange={e => setAppVersionMin(e.target.value)} label="AppVersion min" styles={textFieldStyles} placeholder={''} />
        <TextField value={appVersionMax} onChange={e => setAppVersionMax(e.target.value)} label="AppVersion max" styles={textFieldStyles} placeholder={''} />
        <TextField value={lastFreeVersion} onChange={e => setLastFreeVersion(e.target.value)} label="Last free Version (frame)" styles={textFieldStyles} placeholder={''} />
        <TextField
          value={channel}
          onChange={e => setChannel(e.target.value)}
          label="Channel Restriction"
          styles={textFieldStyles}
          placeholder={'beta or alpha'}
        />{' '}
        <Checkbox checked={free} onChange={e => setFree(e.target.checked)} label="Free Product" styles={checkboxStyles} />
        <Checkbox checked={featured} onChange={e => setFeatured(e.target.checked)} label="Featured Product" styles={checkboxStyles} />
        <Checkbox checked={showOnlyIfOwned} onChange={e => setShowOnlyIfOwned(e.target.checked)} label="Show only if owned" styles={checkboxStyles} />
        <Checkbox checked={hidden} onChange={e => setHidden(e.target.checked)} label="Hidden" styles={checkboxStyles} />
        <div style={buttonWrapStyles}>
          <DefaultButton
            onClick={() => {
              history.goBack();
            }}
          >
            <Text>Cancel</Text>
          </DefaultButton>
          <APIFetchingButton
            text={'Create'}
            onGetData={() => ({
              name,
              sku,
              series,
              dependency,
              installDir,
              imageUrl,
              moodshotUrl,
              logoUrl,
              free,
              trialDays,
              featured,
              featuredSortOrder,
              tagLine,
              manualUrl,
              detailsUrl,
              hidden,
              channel,
              sortOrder,
              appVersionMin,
              appVersionMax,
              lastFreeVersion,
              showOnlyIfOwned,
            })}
            onGetRoute={() => 'products'}
            onSuccess={props.onProductCreated}
            onError={handleError}
            disabled={createButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
}
